import './reviews.css';
import './reviewsMobile.css';
import './reviewsTablet.css';

import {useEffect, useRef, useState} from "react";
import ReactStars from "react-stars/dist/react-stars";
import useWindowDimensions from "../../func/getWindowDimensions";

import {CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Dot} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const BetterReviews = (props) => {
  const { height, width } = useWindowDimensions();
  let [translate, setTranslate] = useState(0);

  const delay = 6000;
  const timeoutRef = useRef(null);
  const [index, setIndex] = useState(0);
  let [reviews, setReviews] = useState([])

  let bannedReviews = ['What a cool place, literally, it was cold.  The jackets never came off.  The beer and beer selection was on point for such a small place.  Also a nice selection of beers to go.  Also very convenient as it was just down the street from our hotel.',
    "First time here but sadly it was the last bar of the day and my memory is a little rusty. I remember the beer selection being good and the pint I had was very nice, just don't ask me what it was! Will go back again if only to check it out with a clearer head."]

  const getReviews = () => {
    fetch(`https://places.googleapis.com/v1/places/ChIJXaFxEAFxfkgR0G7q7KQNVd8?fields=id,displayName,reviews&key=AIzaSyA7UjW4zL9uo0SiaD01FBKjZV22Cg4GY0M`, {
      headers: {
        "Content-Type": "application/json"
      },
      method: "GET"
    }).then(r => {
      return r.json()
    }).then(json => {
      if (json.reviews !== undefined && json.reviews.length > 0) {
        let newReviews = []
        for (let i = 0; i < json.reviews.length; i++) {
          if (!bannedReviews.includes(json.reviews[i].originalText.text) && json.reviews[i].rating === 5) {
            newReviews.push(json.reviews[i])
          }
        }
        setReviews(newReviews)
      }
    })
  }

  useEffect(() => {
    if (reviews.length < 5) {
      getReviews()
    }
  }, []);

  return (
    <div className={"reviewsContainer"}>
      <CarouselProvider
        interval={6000}
        playDirection={'forward'}
        isPlaying={true}
        infinite={true}
        naturalSlideWidth={35}  // We use 100 for full-width here to ensure layout works properly
        naturalSlideHeight={5} // Adjust height as needed
        totalSlides={reviews.length}
        visibleSlides={1}        // Show one slide at a time
        step={1}                 // Move one slide at a time
        isIntrinsicHeight={true}
      >
        <Slider>
          {reviews.map((review, i) => (
            <Slide index={index} key={`slide${review.authorAttribution.displayName}`}>
              <div className={'reviewCard'} key={i}>
                <div className={'reviewHeader'}>
                  <img onClick={() => {
                    window.open(review.authorAttribution.uri, "_blank")
                  }} className={'reviewProfilePic'} src={review.authorAttribution.photoUri}/>
                  <div className={'reviewProfileInfo'}>
                    <div className={'reviewText reviewProfileName'}>{review.authorAttribution.displayName}</div>
                    <div className={'reviewText reviewPublishTime'}>{review.relativePublishTimeDescription}</div>
                  </div>
                </div>
                <div className={'reviewFooter'}>
                  <ReactStars className={'reviewStars'} count={5} value={review.rating} edit={false} size={24}
                              color2={'#ffd700'}/>
                  <div className={'reviewText text'}>{review.originalText.text}</div>
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
        <div className='dotContainer'>
          {reviews.map((item, index) => <Dot className='noSelect' key={`dot${index}`} slide={index}/>)}
        </div>
      </CarouselProvider>
    </div>
  )
}

export default BetterReviews;