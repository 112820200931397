import './heroImages.css';
import './heroImagesMobile.css';
import './heroImagesTablet.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

import {useEffect, useRef, useState} from "react";
import {listAll, getStorage, ref, getDownloadURL} from "firebase/storage";
import useWindowDimensions from "../../func/getWindowDimensions";
import {CarouselProvider, Dot, Slide, Slider} from "pure-react-carousel";

const HeroImages = (props) => {
    const { height, width } = useWindowDimensions();

    const storage = getStorage();
    const imagesRef = ref(storage, 'Hero');

    const [images, setImages] = useState([])
    const [gotImages, setGotImages] = useState(false)

    useEffect(() => {
        // setImages(images.filter(function(item, pos, self) {
        //     return self.indexOf(item) === pos;
        // }))
    }, [images]);

    const getCurrentImages = async () => {
        listAll(imagesRef)
            .then((res) => {
                res.prefixes.forEach((folderRef) => {
                    // All the prefixes under listRef.
                    // You may call listAll() recursively on them.
                });
                res.items.forEach((itemRef) => {
                    // All the items under listRef.
                    getDownloadURL(itemRef).then((url) => {
                        setImages(images => [...images, url]);
                    })

                });
            }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    useEffect(() => {
        if (images.length >= 0) {
            setGotImages(true)
        }
    }, []);

    useEffect(() => {
        if (!gotImages) {
            setGotImages(true)
            getCurrentImages()
        }
    }, [gotImages]);

    return (
      <div className={"heroImagesContainer"}>
          <CarouselProvider
            isPlaying={true}
            interval={3675}
            infinite={true}
            naturalSlideWidth={width > 640 ? 100 / 3 : 100}  // Full width for each slide
            naturalSlideHeight={width <= 640 ? 100 : 25} // Full height for each slide (adjust based on aspect ratio of images)
            totalSlides={images.length} // The number of slides equals the number of images
            visibleSlides={width <= 640 ? 1 : 3}        // Show one image at a time
            step={1}                 // Move one slide at a time
          >
              <Slider>
                  {images.map((url, index) => (
                    <Slide key={index} index={index}>
                        <img className="slide" src={url} alt={`Slide ${index}`} />
                    </Slide>
                  ))}
              </Slider>
              <div className='dotContainer heroDotContainer'>
                  {images.map((item, index) => <Dot className='noSelect' key={`dot${index}`} slide={index}/>)}
              </div>
          </CarouselProvider>
      </div>
    )
}

export default HeroImages;