import React, {useEffect, useState} from 'react';

class Table {
  constructor(id, capacity, currentSize = 0, teams = []) {
    this.id = id;
    this.capacity = capacity;
    this.currentSize = currentSize;
    this.teams = teams;
  }
}

class Team {
  constructor(name, size, date, time) {
    this.name = name;
    this.size = size;
    this.date = date;
    this.time = time;
  }
}

const QuizTables = () => {
  const tables = [
    new Table(1, 8, 0),
    new Table(2, 8, 0),
    new Table(3, 8, 0),
    new Table(4, 8, 0),
    new Table(5, 6, 0),
    new Table(6, 6, 0),
    new Table(7, 5, 0),
    new Table(8, 3, 0),
    new Table(9, 3, 0),
    new Table(10, 2, 0),
    new Table(11, 2, 0),
    new Table(12, 2, 0),
    new Table(13, 2, 0),
  ];

  const tablesN = [
    new Table(2, 8, 0),
    new Table(3, 8, 0),
    new Table(4, 8, 0),
    new Table(5, 6, 0),
    new Table(8, 3, 0),
    new Table(9, 3, 0),
    new Table(10, 2, 0),
    new Table(11, 2, 0),
    new Table(12, 2, 0),
    new Table(13, 2, 0)
  ];

  let testTeams = [
    new Team("Alice", 2, "11 Sep 2024", "7:45pm"),
    new Team("Bob", 2, "11 Sep 2024", "7:45pm"),
    new Team("Carl", 4, "11 Sep 2024", "7:45pm"),
    new Team("Dan", 6, "11 Sep 2024", "7:45pm"),
    new Team("Ed", 3, "11 Sep 2024", "7:45pm"),
    new Team("Fiona", 3, "11 Sep 2024", "7:45pm"),
    new Team("Gary", 4, "11 Sep 2024", "7:45pm"),
    new Team("Henry", 4, "11 Sep 2024", "7:45pm"),
    new Team("Iona", 5, "11 Sep 2024", "7:45pm"),
    new Team("James", 6, "11 Sep 2024", "7:45pm"),
    new Team("Kat", 6, "11 Sep 2024", "7:45pm"),
    new Team("Laura", 4, "11 Sep 2024", "7:45pm"),
    new Team("Max", 4, "11 Sep 2024", "7:45pm")
  ]

  const testTeams2 = [
    new Team("Alice", 4, "11 Sep 2024", "7:45pm"),
    new Team("Bob", 4, "11 Sep 2024", "7:45pm"),
    new Team("Carl", 4, "11 Sep 2024", "7:45pm"),
    new Team("Dan", 4, "11 Sep 2024", "7:45pm"),
    new Team("Ed", 6, "11 Sep 2024", "7:45pm"),
    new Team("Fiona", 6, "11 Sep 2024", "7:45pm"),
    new Team("Gary", 4, "11 Sep 2024", "7:45pm"),
    new Team("Henry", 4, "11 Sep 2024", "7:45pm"),
    new Team("Iona", 5, "11 Sep 2024", "7:45pm"),
    new Team("James", 4, "11 Sep 2024", "7:45pm"),
    new Team("Kat", 4, "11 Sep 2024", "7:45pm"),
    new Team("Laura", 4, "11 Sep 2024", "7:45pm"),
    new Team("Max", 4, "11 Sep 2024", "7:45pm"),
    new Team("Nigel", 3, "11 Sep 2024", "7:45pm"),
    new Team("Ollie", 3, "11 Sep 2024", "7:45pm")
  ];

  const thisWeekTeams = [
    new Team("Rob & Ahmed", 5, "11 Sep 2024", "7:45pm"),
    new Team("Joe & Pip", 8, "11 Sep 2024", "7:45pm"),
    new Team("Toms", 5, "11 Sep 2024", "7:45pm"),
    new Team("Oliver", 2, "11 Sep 2024", "7:45pm"),
    new Team("Lewis", 6, "11 Sep 2024", "7:45pm"),
    new Team("Jack", 6, "11 Sep 2024", "7:45pm"),
    new Team("Ollie", 2, "11 Sep 2024", "7:45pm"),
    new Team("Harry", 6, "11 Sep 2024", "7:45pm"),
    new Team("Holly", 6, "11 Sep 2024", "7:45pm"),
    new Team("Cameron", 3, "11 Sep 2024", "7:45pm"),
    new Team("Sam", 4, "11 Sep 2024", "7:45pm")
  ];

  const thisWeekTeamsNoPerm = [
    new Team("Oliver", 2, "11 Sep 2024", "7:45pm"),
    new Team("Lewis", 6, "11 Sep 2024", "7:45pm"),
    new Team("Jack", 6, "11 Sep 2024", "7:45pm"),
    new Team("Ollie", 2, "11 Sep 2024", "7:45pm"),
    new Team("Harry", 6, "11 Sep 2024", "7:45pm"),
    new Team("Holly", 6, "11 Sep 2024", "7:45pm"),
    new Team("Cameron", 3, "11 Sep 2024", "7:45pm"),
    new Team("Sam", 4, "11 Sep 2024", "7:45pm")
  ];

  let testTeams4 = [
    new Team("Alpha", 3, "12 Sep 2024", "6:30pm"),
    new Team("Beta", 5, "12 Sep 2024", "6:30pm"),
    new Team("Gamma", 2, "12 Sep 2024", "6:30pm"),
    new Team("Delta", 6, "12 Sep 2024", "6:30pm"),
    new Team("Epsilon", 4, "12 Sep 2024", "6:30pm"),
    new Team("Zeta", 2, "12 Sep 2024", "6:30pm"),
    new Team("Eta", 2, "12 Sep 2024", "6:30pm"),
    new Team("Theta", 2, "12 Sep 2024", "6:30pm"),
    new Team("Iota", 3, "12 Sep 2024", "6:30pm"),
    new Team("Kappa", 2, "12 Sep 2024", "6:30pm"),
    new Team("Lambda", 3, "12 Sep 2024", "6:30pm"),
    new Team("Mu", 4, "12 Sep 2024", "6:30pm"),
    new Team("Nu", 2, "12 Sep 2024", "6:30pm"),
    new Team("Xi", 3, "12 Sep 2024", "6:30pm"),
    new Team("Omicron", 4, "12 Sep 2024", "6:30pm"),
    new Team("Pi", 4, "12 Sep 2024", "6:30pm"),
    new Team("Rho", 6, "12 Sep 2024", "6:30pm")
  ];

  const [bookings, setBookings] = useState([]);
  const [assignments, setAssignments] = useState([])
  const [cannotBeSeated, setCannotBeSeated] = useState([])
  const [nullTables, setNullTables] = useState([])
  const [totalPeople, setTotalPeople] = useState([]);

  const findTwoTablesOfTwo = () => {
    const availableTables = tables.filter(t => t.capacity === 2 && t.currentSize === 0);
    return availableTables.length >= 2 ? availableTables.slice(0, 2) : null;
  };

  function assignTables(teams) {
    let newAssignments = [];
    let noSeats = [];
    let noTables = [];
    let totalPeople = 0;

    teams.sort((a, b) => b.size - a.size); // Largest first
    tables.sort((b, a) => b.capacity - a.capacity); // Smallest first

    function assignTable(table, team) {
      newAssignments.push({table, team});
      table.currentSize += team.size;
      table.teams.push(team)
      totalPeople += team.size
    }

    for (const team of teams) {
      for (const table of tables) {
        if (table.capacity !== table.currentSize) { // Check table is not already full
          if (table.currentSize + team.size <= table.capacity) { // If the team size can fit on the table
            if (team.size === 8) {
              if (table.capacity === 8) { // Try put table of 6 on table of 6
                assignTable(table, team);
                break;
              }
            } else if (team.size >= 6) {
              if (table.capacity === 6) { // Try put table of 6 on table of 6
                assignTable(table, team);
                break;
              } else if (table.capacity > 6) {
                assignTable(table, team);
                break;
              }
            } else if (team.size === 5) {
              if (table.capacity === 5 || table.capacity === 6) { // Try put table of 5 on 5 first.
                assignTable(table, team);
                break;
              } else if (table.capacity > 6) {
                assignTable(table, team);
                break;
              }
            } else if (team.size === 4) {
              if (table.capacity === 8 && (table.currentSize + team.size) <= 6) {
                assignTable(table, team);
                break;
              } else {
                let tablesOfTwo = findTwoTablesOfTwo();
                if (tablesOfTwo !== null && tablesOfTwo.length >= 2) {
                  newAssignments.push({table: tablesOfTwo, team: team});
                  for (const subTable of tablesOfTwo) {
                    subTable.currentSize = 2;
                    subTable.teams.push(team);
                  }
                  totalPeople += team.size
                  break;
                } else {
                  if (table.capacity >= 4 && (table.currentSize + team.size) <= 6) {
                    assignTable(table, team);
                    break;
                  }
                }
              }
            } else if (team.size === 3) {
              if (table.capacity === 3) {
                assignTable(table, team);
                break;
              } else {
                if (table.capacity === 8 && table.currentSize + team.size <= 6) {
                  assignTable(table, team);
                  break;
                }
              }
            } else {  // Team is less than or equal to 2
              if (table.capacity <= 2) {
                assignTable(table, team);
                break;
              } else {
                if (table.capacity === 8 && table.currentSize + team.size <= 6) {
                  assignTable(table, team);
                  break;
                }
              }
            }
          } else {
            if (team.size === 4) {
              if (table.capacity === 8 && (table.currentSize + team.size) <= table.capacity) {
                newAssignments.push({table, team});
                table.currentSize += team.size;
                totalPeople += team.size
                break;
              } else {
                let tablesOfTwo = findTwoTablesOfTwo();
                if (tablesOfTwo !== null && tablesOfTwo.length >= 2) {
                  newAssignments.push({table: tablesOfTwo, team: team});
                  for (const subTable of tablesOfTwo) {
                    subTable.currentSize = 2;
                  }
                  totalPeople += team.size
                  break;
                }
              }
            }
          }
        }
      }
    }



    // Check if no team was seated
    for (const team of teams) {
      let found = false
      for (const assignment of newAssignments) {
        if (assignment.team === team) {
          found = true;
        }
      }

      if (!found) {
        noSeats.push(team)
      }
    }

    // Unassigned tables
    for (const table of tables) {
      let found = false
      for (const assignment of newAssignments) {
        if (Array.isArray(assignment.table)) {
          for (const subTable of assignment.table) {
            if (subTable === table) {
              found = true;
            }
          }
        }
        if (assignment.table === table) {
          found = true;
        }
      }

      if (!found) {
        noTables.push(table)
      }
    }

    return {newAssignments, noSeats, noTables, total: totalPeople}
  }

  useEffect(() => {
    setBookings(testTeams4)
  }, []);

  useEffect(() => {
    const {newAssignments, noSeats, noTables, total} = assignTables(bookings);
    setAssignments(newAssignments);
    setCannotBeSeated(noSeats)
    setNullTables(noTables)
    setTotalPeople(total);
  }, [bookings])

  return (
    <div>
      <h2>Bookings:</h2>
      <ul>
        {bookings.map(booking => (
          <li key={booking.name}>
            {booking.name} (Size: {booking.size})
          </li>
        ))}
      </ul>

      <h2>Table Assignments:</h2>
      <ul>
        {/* Sort assignments by table ID before rendering */}
        {assignments.sort((a, b) => {
          const tableAId = Array.isArray(a.table) ? a.table[0].id : a.table.id;
          const tableBId = Array.isArray(b.table) ? b.table[0].id : b.table.id;
          return tableAId - tableBId;
        }).map(assignment => (
          <li key={assignment.team.name}>
            {assignment.team.name} (Size: {assignment.team.size}) assigned to
            {Array.isArray(assignment.table) ? (
              <> Table {assignment.table.map(table => table.id).join(', ')} </>
            ) : (
              <> Table {assignment.table.id} </>
            )}
            (Capacity: {Array.isArray(assignment.table) ? assignment.table.reduce((sum, table) => sum + table.capacity, 0) : assignment.table.capacity})
          </li>
        ))}
      </ul>

      <h2>Cannot Be Seated:</h2>
      <ul>
        {cannotBeSeated.map((team, index) => {
          return (
            <li key={team.name}>
              {team.name} (Size: {team.size})
            </li>
          )
        })}

      </ul>

      <h2>Empty Tables:</h2>
      <ul>
        {nullTables.map((table, index) => {
          return (
            <li key={table.id}>
              ID: {table.id} (Size: {table.capacity})
            </li>
          )
        })}

      </ul>

      <h2>Total People: {totalPeople}</h2>
    </div>
  );
};

export default QuizTables;